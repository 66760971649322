<template src="./emails.html"></template>

<script>
import Http from '@/shared/http-config';

export default {
  name: "Emails",
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  data: function () {
    return {
      user: null,
      accounts: []
    }
  },
  created: function() {
    let self = this;
    self.user = self.$store.getters.getUser;
    Http.request('GET', '/user/emails').then(function (accounts) {
      self.accounts = accounts.data;
    });
  },
  methods: {
    deleteAccount: function (account, key) {
      let self = this;
      self.$bvModal.msgBoxConfirm('Möchtest du diesen Account wirklich löschen? Alle E-Mails in diesem Account gehen dabei verloren!', {
        headerBgVariant: 'primary',
        title: 'Account löschen?',
        okTitle: 'Ja alle E-Mails löschen',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true){
          Http.request('DELETE', '/user/emails/' + account.id).then(() => {
            self.accounts.splice(key, 1);
          });
        }
      });
    },
  }
}

</script>

<style scoped></style>
